@popup-toggle: {
    position: absolute;
    //left: 0;
    //right: 0;
    margin: auto;
    width: 0;
    height: 0;
    border-style: solid;
    transform: translateY(-100%);
    border-width: 0.2*@input-height 0.25*@input-height 0.2*@input-height;
    border-color: transparent transparent @color-bg-secondary transparent;
}

.popup-selected-item {
  cursor: pointer;
}

.popup {
    @popup-width: 20rem;
    @toggle-size: 0.5*@input-height;
    //position: relative;

    .popup-content {
        width: @popup-width;
        color: @base-text-color;
        animation-name: appear;
        animation-duration: 300ms;
        position: absolute;
        // margin-top: @base-padding-v;
        // right: 0;
        margin-top: @base-padding-v;
        margin-left: -0.5*@popup-width;
        background: @color-bg-secondary;
        border-radius: @base-border-radius;
        border: @input-border;
        //opacity: 0;
        display: none;
    }

    .overlay {
        display: none;
        //background: transparent;
    }

    &::after {
        animation-name: appear;
        animation-duration: 300ms;
        z-index: 3; // OverOverlay layer to ensure that it's over Overlay Layer
        content: "";
        display: none;
        @popup-toggle();
        margin-top: @base-padding-v + @input-border-size;
        margin-left: -0.5*@toggle-size;
    }

    &.show {
        .popup-content {
            display: block;
            //opacity: 1;
            z-index: 3; // OverOverlay layer to ensure that it's over Overlay Layer
        }

        .overlay {
            display: block;
        }

        &::after {
            display: block;
        }
    }

    &.align-left {
        .popup-content {
            margin-left: 0;
        }

        &::after {
            margin-left: @base-margin-h + 0.5*@toggle-size;
        }
    }

    &.align-right {
        .popup-content {
            margin-left: -@popup-width;
        }

        &::after {
            margin-left: -(@base-margin-h + 1.5*@toggle-size);
        }
    }

    // use full screen popup for on mobile and tablet
    @media only screen and (max-width: @grid-l - 1) {
        .popup-content {
            margin-left: auto !important;
            margin-right: auto !important;
            left: 10%;
            right: 10%;
            width: auto;
        }

        &::after {
            display: none !important;
        }
    }
}
