.card {
  position: relative;
  background: #F5F5F5;
  border-radius: 0 0 @base-border-radius @base-border-radius;
  display: inline-block;
  height: 100%;
  padding-bottom: 12.29%;
  cursor: pointer;

  &::after {
    content: "";
    display: block;
    clear: both;
  }
}

.card-login {
  position: relative;
  background-color: @color-bg-secondary;
  border-radius: @base-border-radius;
  display: inline-block;
  height: 100%;
  padding-bottom: 12.29%;
  cursor: pointer;
  padding-top: 8%;

  &::after {
    content: "";
    display: block;
    clear: both;
  }
}

.card-image {
    height: 14rem; // 224px;
}

.card-header {
  padding: @base-padding-v @base-padding-h;
  word-break: break-word;
}

.card-body {
  padding: 0rem @base-padding-h;
  border-radius: 0 0 @base-border-radius @base-border-radius;
}

.card-attachment {
  display: flex;
  justify-content: space-between;
  background-color: var(--primary-application-color);
  padding: 20px;
  color: var(--color-dynamic);
  cursor: default;
  flex-direction: row;
  flex-flow: row wrap;

  &-icon {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    i {
      font-size: 16px;
      width: auto;
      color: red;
    }
  }
}

.card-body-long-description {
  overflow-wrap: break-word;
  min-height: 70px;

  &-container {
    position: relative;
    max-height: 70px;
    overflow: hidden;
  }

  &-overlay {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    height: 20px;
    background-image: linear-gradient(180deg, transparent, #F5F5F5);
  }
}

.card-footer {
  padding: @base-padding-v @base-padding-h;
}

.centered-image {
  overflow: hidden;
  height: 100%;

  img {
    display: inline-block;
    max-width: none;
    height: 14rem;
    width: 100%;
    margin: 0;
    object-fit: cover;
  }
}

.preview-image {
  overflow: hidden;
  height: 100%;

  img {
    display: inline-block;
    max-width: none;
    height: 14rem;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
  }
}


.grow {
  transition: transform 0.5s ease;
}

.grow:hover {
  transform: scale(1.05);
  box-shadow:0 0 6px 6px rgba(0,0,0,.2);
  transition: transform 0.5s ease;
}
