.with-toggle {
    position: relative;
    background-color: @color-bg-secondary;
    margin: @base-margin-v 0;
    border-color: @color-disabled;
    border-radius: 3px;//@input-border-radius;

    .toggle {
        position: absolute;
        top: @input-border-size;
        bottom: @input-border-size;
        right: @base-margin-h;
        width: @input-height;
        line-height: @input-height;
        height: @input-height;
        text-align: center;
        transition: all 300ms ease;
    }

    input, select, .form-control {
        position: relative;
        background-color: transparent;
        margin: 0;
        appearance: none;

        &::-ms-expand {
            display: none;
        }
    }

    &.focusable:focus-within, &:hover, &.active {
        color: @color-primary;
        border-color: @color-primary;
    }

    &.focusable:focus-within, &.active {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        .toggle {
            transform: rotate(180deg);
        }
    }
}
