//Terms and conditions dialog
.dialog-container {
  box-shadow: 0 0 10px 10px rgba(0,0,0,.2);
  min-width: 270px;
}

.dialog {
  position: relative;
  background-color: @color-bg-secondary;
  display: inline-block;
  height: 100%;
  cursor: pointer;
  padding: 5%;
  width: 100%;

  &::after {
    content: "";
    display: block;
    clear: both;
  }
}

.dialog-header {
  padding: @base-padding-v @base-padding-h;
  word-break: break-word;
  width: 100%;
  height: 120px;
}

.dialog-body {
  padding: 0 @base-padding-h;
  border-radius: 0 0 @base-border-radius @base-border-radius;
  height: 50vh;
  overflow:auto;
}

.dialog-footer {
  margin-top:50px;

  &-button {
    &--disabled {
      cursor: default;
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

.dialog-logo {
  max-width: 80%;
  max-height: 90px;
  display: inline-block;
  margin-left: 20px;
  display: inline-block;
  padding: 5px;
  box-shadow: 0 0 5px 5px rgba(0,0,0,.1);
  display: flex;
  align-items: center;
  background-color: rgba(255,255,255,0.7);
  color: rgba(180, 180, 180, 1);
  border: 1px solid rgba(0,0,0,0.8);
}


//Extra-services dialog
#dialog-container-services-messenger {
  padding: 1.25rem 0;
  width: 100%;
  max-width: 750px;
  margin: 0 auto;
  min-height: 100%;
  height: 100%;
  left: auto;

  @media only screen and (max-width: @grid-l-min-breakpoint) {
    overflow: hidden;
    width: 100%;
    padding: 0;
  }
}

.dialog-container-services {
  box-shadow: 0 0 10px 10px rgba(0,0,0,.2);
  text-align: center;
  background-color: #EEEEEE;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.dialog-services {
  background-color: #EEEEEE;
  display: inline-block;
  flex: 1;
  overflow: auto;
}

.dialog-body-long-description {
  overflow-wrap: break-word;
  overflow-y: hidden;
  text-align:left;

  &-container {
    margin-bottom: 50px;
    position: relative;
  }

  &-animate {
    &-overlay {
      position: absolute;
      background-image: linear-gradient(180deg, transparent, #EEEEEE);
      height: 25%;
      left: 0;
      right: 0;
      bottom: 0;
    }

    &-icon {
      color: var(--primary-application-color);
      position: absolute;
      width: 100%;
      justify-content: center;
      bottom: -15px;
      display: flex;
      cursor: pointer;

      &-fas {
        transition-duration: 0.4s;
        transition-property: transform;

        &-active {
          transform: rotate(180deg);
        }
      }
    }
  }
}

.dialog-body-room {
  background-color: white;
  border: 2px solid grey;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &-title {
    padding: 10px;
    border-bottom: 1px solid grey;
    font-weight: 700;
  }

  &-date {
    font-weight: 400;
    padding-top: 15px;
    padding-bottom: 15px;
    width:33.33%;
  }

  // Target occurrence 1+ of a date child
  & > &-date ~ &-date {
    border-top: 1px solid grey;
  }
}

.dialog-header {
  &-button-close {
    position: absolute;
    z-index: 2;
    right: 0;
    top: 0;
    width: 75px;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;

    &-icon {
      font-size: 2em !important;
    }
  }

  &-services {
    word-break: break-word;
    background-color: var(--primary-application-color);
    font-size: x-large;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    min-height: 75px;
    max-height: 300px;
  }

  &-background-container {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    right: 0;
    opacity: 0.2;
    filter: blur(5px);
  }

  &-image {
    width: auto;
    height: 100%;
    position: relative;

    &-container {
      position: relative;
      overflow: hidden;
    }
  }

  p {
    background-color: #2f475f;
    padding: 0.5rem 0;
  }
}

.dialog-body-services {
  padding: @base-padding-v (@base-padding-h + @base-padding-h);
  border-radius: 0 0 @base-border-radius @base-border-radius;
  overflow: auto;
  background-color: #EEEEEE;
}

.dialog-footer {
  &-services {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem (@base-padding-h + @base-padding-h);
    margin-top: 15px;
    flex-direction: column;
  }

  &-button {
    min-width: 150px;

    &:last-child {
      margin-left: 5px !important;
    }
  }

  &-total {
    align-self: end;
    padding-bottom: 10px;
  }
}

.dialog-logo-services {
  max-width: 250px;
  max-height: 70px;
  display: inline-block;
  margin-left: 20px;
}

.dialog-add-service-day {
  &-inline-box {
    display: flex;
    flex-direction: row;
    justify-content:center;
    flex-grow: 1;
    user-select: none;
    align-items: center;
    background-color: transparent;
  }

  &-wrapper {
    margin:5px;
    padding:10px;
    border: 1px solid black;
  }

  &-help-text{
      width:100%;
      text-align:left;
      margin-left: 4%;
      font-size:11px;
      margin-top:-25px;
  }
}

.dialog-add-description {
  font-weight: 700;
  flex-basis: 20%;
}

.dialog-add-guest-count {
  white-space: nowrap;
  margin-left: 5px;
  margin-right: 5px;
}

.dialog-add-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-weight: 700;
  cursor: pointer;
  border-radius: 50%;
  border:0.125rem solid var(--primary-application-color);
  background-color:white;

  &-container {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  &-size {
    max-width: 200px;
  }

  &--disabled {
    pointer-events: none;
  }
}

//SoldOut dialog
.dialog-container-soldout {
  box-shadow: 0 0 10px 10px rgba(0,0,0,.2);
  width: 20vw;
  height: 10vh;
  text-align: center;
  background-color: #EEEEEE;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 220px;
  min-height: 150px;
}

.dialog-soldout {
  position: relative;
  background-color: #EEEEEE;
  display: inline-block;
  height: 20%;
  padding: 5%;
  width: 100%;

  &::after-soldout {
    content: "";
    display: block;
    clear: both;
  }
}

.dialog-header-soldout {
  padding: @base-padding-v @base-padding-h;
  word-break: break-word;
  height: 120px;
  background-color: var(--primary-application-color);
  width: 100%;
  font-size: xx-large;
}

.dialog-body-soldout {
  padding: 0rem @base-padding-h;
  border-radius: 0 0 @base-border-radius @base-border-radius;
  overflow: auto;
  height: 25vh;
  background-color: #EEEEEE;
  width: 100%;
}

.dialog-footer-soldout {
  @media only screen and (min-width: 1170px) {
    margin-top: -16%;
  }

  @media only screen and (max-width: 1170px) {
      position:relative;
      bottom:30%;
  }
}


//Image Gallery dialog desktop
@media only screen and (min-width: 1170px) {
  .dialog-container-gallery {
    box-shadow: 0 0 5px 5px rgba(0,0,0,.5);
    width: 980px;
    height: 580px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: gray;
  }

  .show-desktop {
    display: block;
  }

  .show-mobile{
    display:none;
  }
}

.close-icon {
  position: absolute;
  right: 20px;
  top: 20px;
}

.content-title {
  max-width: 350px;
  margin-left: 25px;
  margin-top: 10px;
  font-size: 20px;
  overflow: auto;
  max-height: 6rem;
  color: white;
}

.content-body {
  max-width: 350px;
  max-height:15rem;
  margin-left: 25px;
  margin-top: 25px;
  font-size: 18px;
  overflow:auto;
  color:white;
}

.content-footer-left {
  position: absolute;
  left: 20px;
  bottom: 20px;
  color: white;
}

.content-footer-right {
  position: absolute;
  right: 10px;
  bottom: 10px;
  padding: 10px;
  background: linear-gradient(to left, rgba(0, 0, 0), rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0.2));
}

.closeIconBackground {
  display: inline-block;
  width: 27px;
  height: 25px;
  background-color: black;
  line-height: 34.5px;
  text-align: center;
  border-radius: 34px;
}


//Image Gallery dialog mobile

@media only screen and (max-width: 1170px) {
  .dialog-container-gallery-mobile {
    box-shadow: 0 0 2px 2px white;
    width: 97vw;
    height: 90vh;
    position: fixed;
    top: 50%;
    left: 50%;
    background-color: #EEEEEE;
    transform: translate(-50%, -50%);
    overflow:auto;
  }

  .show-desktop {
    display: none;
  }

  .show-mobile {
    display: block;
  }
}

.content-title-mobile {
  max-width: 100%;
  margin-left: 25px;
  margin-top: 1vh;
  font-size: 18px;
  color:black;
  max-height:12.5vh;
  overflow:auto;
}

.content-body-mobile {
  max-width: 100%;
  max-height:34vh;
  margin-left: 25px;
  margin-top: 1vh;
  font-size: 15px;
  color: black;
  overflow:auto;
}

.content-footer-mobile {
  display: flex;
  justify-content: center;
}

.content-imageSelect-mobile {
  background: linear-gradient(rgba(0, 0, 0.4), rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0.4));
  height:5%;
}

.content-Image {
  height:30vh;
  object-fit:cover;
}


//Message center dialog
.dialog-container-Message {
  box-shadow: 0 0 10px 10px rgba(0,0,0,.2);
  width: 40vw;
  height: 30vh;
  text-align: center;
  background-color: #EEEEEE;
  position: fixed;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 300px;
  min-height: 100px;
  max-height: 290px;
  max-width: 620px;
}

.dialog-Message {
  position: relative;
  background-color: #EEEEEE;
  display: inline-block;
  height: 100%;
  width: 100%;

  &::after-Message {
    content: "";
    display: block;
    clear: both;
  }
}

.dialog-body-Message {
  padding: @base-padding-v @base-padding-h;
  overflow: auto;
  max-height: 48vh;
  background-color: white;
  width: 100%;
}

.dialog-footer-Message {
  background-color: var(--primary-application-color);
  height:40%;
}


//Table reservation dialog
.dialog-container-table {
  box-shadow: 0 0 10px 10px rgba(0,0,0,.2);
  width: 20vw;
  height: 40vh;
  text-align: center;
  background-color: #EEEEEE;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 339px;
  min-height: 550px;
}

.dialog-table {
  position: relative;
  background-color: #EEEEEE;
  display: inline-block;
  height: 20%;
  padding: 5%;
  width: 100%;

  &::after-table {
    content: "";
    display: block;
    clear: both;
  }
}

.dialog-header-table {
  padding: @base-padding-v @base-padding-h;
  word-break: break-word;
  width: 20%;
  height: 120px;
  background-color: var(--primary-application-color);
  width: 100%;
  font-size: xx-large;
  color: var(--color-dynamic);
}

.dialog-body-table {
  padding: 0rem @base-padding-h;
  border-radius: 0 0 @base-border-radius @base-border-radius;
  overflow: auto;
  height: 22vh;
  background-color: #EEEEEE;
  width: 100%;
}

.dialog-footer-table {
  margin-top: 1%;
}

.dialog-logo-table {
  max-width: 250px;
  max-height: 70px;
  display: inline-block;
  margin-left: 20px;
}

.dialog-table-day {
  user-select: none;
  width: 100%;
  height: 6vh;
  cursor: pointer;
  align-items: center;
  border: 1px solid;
  min-height: 40px;
  background-color: white;
  margin-top: 5%;
}


//Giftcard dialog
.dialog-container-giftcard {
  box-shadow: 0 0 10px 10px rgba(0,0,0,.2);
  width: 25vw;
  height: 30vh;
  text-align: center;
  background-color: #EEEEEE;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 339px;
  min-height: 450px;
}

.dialog-giftcard {
  position: relative;
  background-color: #EEEEEE;
  display: inline-block;
  height: 35vh;
  padding: 5%;
  width: 100%;

  &::after-giftcard {
    content: "";
    display: block;
    clear: both;
  }
}

.dialog-header-giftcard {
  padding: @base-padding-v @base-padding-h;
  word-break: break-word;
  width: 20%;
  height: 120px;
  background-color: var(--primary-application-color);
  width: 100%;
  font-size: xx-large;
}

.dialog-body-giftcard {
  padding: 0rem @base-padding-h;
  border-radius: 0 0 @base-border-radius @base-border-radius;
  overflow: auto;
  height: 20vh;
  background-color: #EEEEEE;
  width: 100%;
}

.dialog-footer-giftcard {
  margin-top: 1%;
}

//New login(username password) content dialog style
.dialog-container-activate {
  box-shadow: 0 0 10px 10px rgba(0,0,0,.2);
  min-width: 270px;
  margin-top: 10%;
  left:22%;
  width: 40vw;
  height:45vh;
  position:absolute;
}

.dialog-activate {
  position: relative;
  background-color: @color-bg-secondary;
  display: inline-block;
  height: 100%;
  padding: 5%;
  width: 100%;

  &::after {
    content: "";
    display: block;
    clear: both;
  }
}

.dialog-header-activate {
  padding: @base-padding-v @base-padding-h;
  word-break: break-word;
  width: 100%;
  height: 120px;
  background-color: var(--primary-application-color);
  text-align:center;
  color:white;
}

.dialog-body-activate {
  padding: 0rem @base-padding-h;
  border-radius: 0 0 @base-border-radius @base-border-radius;
  height: 50vh;
  overflow: auto;
}

.dialog-footer-activate {
  margin-top: 50px;
}


//Conference-Hotel-Change
.conference-container-search {
  box-shadow: 0 0 10px 10px rgba(0,0,0,.2);
  width: 25vw;
  height: 60vh;
  text-align: center;
  background-color: #EEEEEE;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 339px;
  min-height: 550px;
  cursor:pointer;
}

.conference-search {
  position: relative;
  background-color: #EEEEEE;
  display: inline-block;
  height: 20%;
  padding: 5%;
  width: 100%;

  &::after-services {
    content: "";
    display: block;
    clear: both;
  }
}

.conference-header-search {
  padding: @base-padding-v @base-padding-h;
  word-break: break-word;
  width: 20%;
  height: 10vh;
  background-color: var(--primary-application-color);
  width: 100%;
  font-size: xx-large;
}

.conference-body-search {
  padding: 0rem @base-padding-h;
  border-radius: 0 0 @base-border-radius @base-border-radius;
  overflow: auto;
  height: 45vh;
  background-color: #EEEEEE;
  width: 100%;
}

.conference-hotelcard-search {
  width: 100%;
  position: relative;
  height: 6rem;
  background-color: white;
  margin-top: 10px;
}


//Conference-guest-picker
.conference-container-picker {
  box-shadow: 0 0 10px 10px rgba(0,0,0,.2);
  width: 15vw;
  height: 10vh;
  text-align: center;
  background-color: #EEEEEE;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  font-size: 20px;
  text-align-last: center;
  text-align: center;

  select
  {
      height:4vh;
  }
}

.conference-container-guestcount {
  padding: @base-padding-v @base-padding-h;
  word-break: break-word;
  width: 20%;
  height: 5vh;
  background-color: var(--primary-application-color);
  width: 100%;
  font-size: x-large;
}
