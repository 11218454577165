.wireframe {
  *
  {
    outline: dashed red;
  }
}

body {
  font-size: 1rem;
  line-height: @base-line-height;
  color: @base-text-color;
  background: #EEEEEE;
  background: -webkit-linear-gradient(to bottom, #EEEEEE, #DBDBDB, var(--primary-application-color));
  background: linear-gradient(to bottom, #EEEEEE, #DBDBDB, var(--primary-application-color));
  margin: 0;
  height: 100%;
  position: relative;
  font-family: var(--primary-application-font);

  &.addBackGround {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &.addBackGroundWithAnimation {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    animation: moveIt 27s alternate infinite;
    transition: none;
  }

  &.mobileBackground {
    background: #EEEEEE !important;
    background: -webkit-linear-gradient(to bottom, #EEEEEE, #DBDBDB, var(--primary-application-color)) !important;
    background: linear-gradient(to bottom, #EEEEEE, #DBDBDB, var(--primary-application-color)) !important;
  }
}

.glass-effect {
  background-color: rgba(238,238,238,0.52);
  backdrop-filter: blur(15px);
}

.zoom-content-effect {
  -webkit-animation: bummer 1s;
  animation: bummer 1s;
  -webkit-transform: scale(0.8,0.8);
  transform: scale(0.8,0.8);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes bummer {
  100% {
    -webkit-transform: scale(1,1);
  }
}

@keyframes bummer {
  100% {
    transform: scale(1,1);
  }
}


@media only screen and (min-width: 1295px) {
  .desktop-show {
    display:block;
  }
}


@media only screen and (max-width: 1295px) {
  .mobile-hide {
    display:none;
  }
}

@media only screen and (min-width: 1295px) {
  .desktop-hide {
    display: none;
  }
}


@media only screen and (max-width: 1295px) {
  .mobile-show {
    display: block;
  }
}

@keyframes moveIt {
  from {
    background-position: bottom left;
  }

  to {
    background-position: top right;
  }
}

a, .link {
    font: inherit;
    border: none;
    color: inherit;
    cursor: pointer;
    background-color: transparent;
    text-decoration: none;
    padding: 0;

    &:hover, &.active, &:focus {
        text-decoration: underline;
    }
}

hr.new1 {
  border-top: 2px solid white;
}

.dynamicTextColor {
  color: @dynamicColor;
}

.applicationColor {
  color: var(--primary-application-color);
}

.applicationColorTextIcon {
  color: var(--primary-application-color-text-icon);
}

.wrap-text {
  width:120px;
  overflow-wrap: break-word;
}

.main-container {
  min-height: 100vh;
  overflow: hidden;
  display: block;
  position: relative;
  padding-bottom: 100px;
}

.footer {
  color: var(--color-dynamic);
  background-color: var(--primary-application-color);
  padding: 10px 0;
  margin: 20px auto 0;
  position: absolute;

  @media only screen and (max-width: 768px) {
    bottom: 5.5vh;
  }

  @media only screen and (min-width: 768px) {
    bottom: 0;
  }

  width: 100%;
  font-size: 15px;

  .col {
    margin-bottom: 0;

    > div {
      display: inline-block;
    }
  }

  @media only screen and (max-width: @grid-l - 1) {
    .col > div {
      text-align: center;
    }
  }
}

.no-payment-required-container {
  .text {
    padding: 40px;
    background-color: #AEE8C5;
  }
}

.tab {
  overflow: hidden;
  background-color: gray;
  width: 100%;
  min-width: 310px;
  box-shadow: 0 0 5px 5px rgba(0,0,0,.1);

  > .tablinks {
    width: 50%;
    text-transform: uppercase;
  }

  &.hideTab {
    display: none;
  }
}

.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}

.tab button:hover {
  background-color: #ddd;
}

.tab button.active {
  background-color: lightgray;
}

.tabcontent {
  display: none;
  padding: 26px 40px;
  border-top: none;
  -webkit-animation: fadeEffect 1s;
  animation: fadeEffect 1s;
  width: 100%;
  max-width:920px;
  background-color: rgba(255, 255, 255, 1);
  margin-bottom: 90px;
  box-shadow: 0 0 5px 5px rgba(0,0,0,.1);

  &.tabOpen {
    display: block;
  }
}

@-webkit-keyframes fadeEffect {
  from {
    background-color: rgba(255, 255, 255, 0);
  }

  to {
    background-color: rgba(255, 255, 255, 0.9);
  }
}


.accordion {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
}

.activeacc, .accordion:hover {
  background-color: #ccc;
}

.panel {
  padding: 0 18px;
  display: none;
  background-color: white;
  overflow: hidden;
}



.fadeBody {
  animation: fadein 1.5s;
  -moz-animation: fadein 1.5s;
  -webkit-animation: fadein 1.5s;
  -o-animation: fadein 1.5s;
}


.cut-text {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 195px;
  white-space: nowrap;
}


@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-moz-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-o-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media only screen and (max-width: 1295px) {
  .mobileLogo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom:30px;
    margin-top:-20px;
    max-height:100px;
    max-width:120px;
  }
}
@media only screen and (min-width: 1295px) {
  .mobileLogo-remove {
    display: none;
  }
}

.icon-bar {
  position: fixed;
  top: 11.7rem;
  right: 0;
  z-index: 2;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.icon-bar a {
  display: block;
  text-align: center;
  padding: 16px;
  transition: all 0.3s ease;
  color: var(--color-dynamic);
  font-size: 20px;
}

.icon-bar a:hover {
  background-color: Highlight;
}

.basket {
  background: var(--primary-application-color);
}

.messages {
  background: #EC9235;
}

.content {
  margin-left: 75px;
  font-size: 30px;
}


@media only screen and (min-width: 1295px) {
  .basket-message {
    width: 30vw;
    max-width: 400px;
    max-height: 70%;
    background-color: rgba(255, 255, 255, 0.9);
    top: 10rem;
    position: fixed;
    right: 4%;
    word-break: break-word;
    z-index: 2;
    color: black;
    display: none;
    background-color: var(--primary-application-color);
    color: var(--color-dynamic);

    &.hideMessage {
      display: block;
    }

    overflow: auto;
    overflow-x: hidden;
  }
}

.basket-message:after {
  content: '';
  position: absolute;
  right: 0;
  top: 20%;
  width: 0;
  height: 0;
  border: 25px solid transparent;
  border-left-color: var(--primary-application-color);
  border-right: 0;
  border-top: 0;
  margin-right: -25px;
  }

@media only screen and (max-width: 1295px) {
  .basket-message {
    width: 100%;
    height:80%;
    max-height:81%;
    background-color: rgba(255, 255, 255, 0.9);
    top: 13.7rem;
    position: fixed;
    right: 2px;
    word-break: break-word;
    z-index: 2;
    color: black;
    display: none;
    background-color: var(--primary-application-color);
    color: var(--color-dynamic);

    &.hideMessage {
      display: block;
    }

    overflow: auto;
    overflow-x: hidden;
  }
}

.zoomTransformAnimation {
  animation: main 0.5s;
  transform-origin: 100% 50%;
}

@keyframes main {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

.borderAroundContent {
  border-style: dashed;
  border-width: 1.5px;
  border-color: dimgray;
  padding:5px;
}

.ribbon {
  width: 65%;
  height: 40px;
  line-height: 40px;
  position: absolute;
  top: 40px;
  right: -55px;
  z-index: 1;
  overflow: hidden;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border: 1px dashed var(--color-dynamic);
  box-shadow: 0 0 0 3px var(--primary-application-color), 0px 21px 5px -18px rgba(0,0,0,0.6);
  background: var(--primary-application-color);
  text-align: center;
  color: var(--color-dynamic);
}

.ribbon-small {
  width:66%;
  height: 40px;
  line-height: 40px;
  position: absolute;
  right:-55px;
  top:30px;
  z-index: 1;
  overflow: hidden;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border: 1px dashed var(--color-dynamic);
  box-shadow: 0 0 0 3px var(--primary-application-color), 0px 21px 5px -18px rgba(0,0,0,0.6);
  background: var(--primary-application-color);
  text-align: center;
  color: var(--color-dynamic);
}

.wrap-small {
  position: relative;
  overflow: hidden;
}

.wrap {
  width: 100%;
  height: 188px;
  position: absolute;
  overflow: hidden;
}

.glow {
  background: rgb(255,255,255);
  width: 40px;
  height: 100%;
  z-index: 4;
  position: absolute;
  -webkit-animation: flow 3s linear 1;
  -webkit-transform: skew(20deg);
  -moz-transform: skew(20deg);
  -o-transform: skew(20deg);
  background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 1%,rgba(255,255,255,1) 100%);
  border-left: 1px solid #fff;
  opacity:0;
}

@-webkit-keyframes flow {
  0% {
    left: -20px;
    opacity: 0;
  }

  50% {
    left: 100px;
    opacity: 0.3;
  }

  100% {
    left: 180px;
    opacity: 0;
  }
}

.badge-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  pointer-events: none;
}

.badge {
  position: absolute;
  min-width: .5rem;
  padding: .17rem .32rem;
  border-radius: 50%;
  font-size: 10px;
  left: 10px;
  bottom: 10px;
  text-align: center;
  background: red;
  color: #fefefe;
  pointer-events: none;
}


  @import "./list-group.less";
  @import "./overlay.less";
  @import "./form.less";
  @import "./nav.less";
  @import "./grid.less";
  @import "./card.less";
  @import "./button.less";
