@media only screen and (min-width: 1025px) {
  .navigationTile {
    width: 17rem;
    height: 14rem;
    box-shadow: 0 0 2px 2px rgba(0,0,0,.2);
    cursor: pointer;
    text-align: center;

    i {
      font-size: 90px;
      margin-top: 30%;
      margin-right: 75px;
    }

    p {
      font-size: 25px;
      margin-top: 40px;
    }
  }


  .landing-content-multi {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 50vh;
    margin-left: 12%;
    margin-top: 3vh;
  }

  .landing-content-single {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 77vh;
    margin-left: 12.5%;
    margin-top: 4vh;
  }
}

@media (max-width:1024px) and (min-width:720px) {
  .navigationTile {
    width: 13rem;
    height: 12rem;
    background-color: rgba(238,238,238,0.92);
    box-shadow: 0 0 2px 2px rgba(0,0,0,.2);
    cursor: pointer;
    text-align: center;
    margin-bottom: 2rem;

    i {
      font-size: 40px;
      margin-top: 30%;
    }

    p {
      font-size: 25px;
      margin-top: 10px;
    }
  }

  .landing-content-multi {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 50vh;
    margin-top: 3vh;
  }

  .landing-content-single {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 50vh;
    margin-top: 4vh;
  }
}

@media (max-width:720px) and (min-width:0px) {
  .navigationTile {
    width: 11rem;
    height: 10rem;
    background-color: rgba(238,238,238,0.92);
    box-shadow: 0 0 2px 2px rgba(0,0,0,.2);
    cursor: pointer;
    text-align: center;
    margin-bottom: 2rem;

    i {
      font-size: 25px;
      margin-top: 30%;
    }

    p {
      font-size: 15px;
      margin-top: 10px;
    }
  }

  .landing-content-multi {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 50vh;
    margin-top: 3vh;
  }

  .landing-content-single {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 50vh;
    margin-top: 4vh;
  }
}

.hotelSelector {
  background: -webkit-gradient(linear, 0 0, 100% 0, from(transparent), to(transparent), color-stop(50%, rgb(211, 211, 211)));
  padding: 15px;
}

.hotelSpacing {
  div + div {
    margin-left: 2.5rem;
  }

  padding-left: 18px;
  padding-right: 15px;
}

.picker-tile {
  margin-top: 20px;
  width: 7rem;
  height: 6rem;
  box-shadow: 0 0 2px 2px rgba(0,0,0,.2);
  cursor: pointer;
  text-align: center;

  i {
    font-size: 90px;
    margin-top: 30%;
    margin-right: 75px;
  }
}

.hotel-active {
  transform: scale(1.1);
  box-shadow: 0 0 6px 6px rgba(0,0,0,.2);
  border-style: solid;
  border-color: var(--primary-application-color);
}

.landing-content {
  animation-name: appear;
  animation-duration: 300ms;
  margin-bottom: 3rem 0;

  @media only screen and (min-width: @grid-m) {
    margin: 1.8rem;
  }

  @media only screen and (max-width: 768px) {
    padding-bottom: 10vh;
  }
}
