//KEYFRAMES
@keyframes sk-chase {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot {
  80%, 100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4);
  }

  100%, 0% {
    transform: scale(1.0);
  }
}

//GLOBAL LOADER
.sk-chase {
  width: 70px;
  height: 70px;
  position: relative;
  animation: sk-chase 2.8s infinite linear both;
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2.0s infinite ease-in-out both;
}

.sk-chase-dot:before {
  content: '';
  display: block;
  width: 25%;
  height: 25%;
  background-color: #d87219;
  border-radius: 100%;
  animation: sk-chase-dot-before 3.5s infinite ease-in-out both;
}

.sk-chase-dot:nth-child(1) {
  animation-delay: -1.1s;
}

.sk-chase-dot:nth-child(2) {
  animation-delay: -1.0s;
}

.sk-chase-dot:nth-child(3) {
  animation-delay: -0.9s;
}

.sk-chase-dot:nth-child(4) {
  animation-delay: -0.8s;
}

.sk-chase-dot:nth-child(5) {
  animation-delay: -0.7s;
}

.sk-chase-dot:nth-child(6) {
  animation-delay: -0.6s;
}

.sk-chase-dot:nth-child(1):before {
  animation-delay: -1.1s;
}

.sk-chase-dot:nth-child(2):before {
  animation-delay: -1.0s;
}

.sk-chase-dot:nth-child(3):before {
  animation-delay: -0.9s;
}

.sk-chase-dot:nth-child(4):before {
  animation-delay: -0.8s;
}

.sk-chase-dot:nth-child(5):before {
  animation-delay: -0.7s;
}

.sk-chase-dot:nth-child(6):before {
  animation-delay: -0.6s;
}


//Global loader
@media only screen and (max-width: 4000px) {
  .load-message {
    position: fixed;
    top: 50%;
    left: 46.5%;
    font-size: 20px;
    width: 9.5rem;
    color: white;
  }

  .loader-box {
    position: fixed;
    top: 41%;
    left: 48%;
    font-size: 20px;
    width: 9.5rem;
    color: white;
  }
}

@media only screen and (max-width: 1920px) {
  .load-message {
    position: fixed;
    top: 50%;
    left: 45%;
    font-size: 20px;
    width: 9.5rem;
    color: white;
  }

  .loader-box {
    position: fixed;
    top: 39%;
    left: 46.5%;
    font-size: 20px;
    width: 9.5rem;
    color: white;
  }
}

@media only screen and (max-width: 1170px) {
  .load-message {
    position: fixed;
    top: 52.5%;
    left: 34%;
    font-size: 20px;
    width: 9.5rem;
    color: white;
  }

  .loader-box {
    position: fixed;
    top: 37%;
    left: 40%;
    font-size: 20px;
    width: 9.5rem;
    color: white;
  }
}




//CALENDER LOADER
.sk-chase-small {
  width: 40px;
  height: 40px;
  position: relative;
  animation: sk-chase 2.8s infinite linear both;
}

.sk-chase-dot-small {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2.0s infinite ease-in-out both;
}

.sk-chase-dot-small:before {
  content: '';
  display: block;
  width: 25%;
  height: 25%;
  background-color: #d87219;
  border-radius: 100%;
  animation: sk-chase-dot-before 3.5s infinite ease-in-out both;
}

.sk-chase-dot-small:nth-child(1) {
  animation-delay: -1.1s;
}

.sk-chase-dot-small:nth-child(2) {
  animation-delay: -1.0s;
}

.sk-chase-dot-small:nth-child(3) {
  animation-delay: -0.9s;
}

.sk-chase-dot-small:nth-child(4) {
  animation-delay: -0.8s;
}

.sk-chase-dot-small:nth-child(5) {
  animation-delay: -0.7s;
}

.sk-chase-dot-small:nth-child(6) {
  animation-delay: -0.6s;
}

.sk-chase-dot-small:nth-child(1):before {
  animation-delay: -1.1s;
}

.sk-chase-dot-small:nth-child(2):before {
  animation-delay: -1.0s;
}

.sk-chase-dot-small:nth-child(3):before {
  animation-delay: -0.9s;
}

.sk-chase-dot-small:nth-child(4):before {
  animation-delay: -0.8s;
}

.sk-chase-dot-small:nth-child(5):before {
  animation-delay: -0.7s;
}

.sk-chase-dot-small:nth-child(6):before {
  animation-delay: -0.6s;
}

.loaderbox-small {
  position: absolute;
  right: 4%;
  bottom: 10%;
  z-index: 20;
  color: black;
  background-color: white;
  width: 5rem;
}

.load-message-small {
  position: absolute;
  right: -5%;
  bottom: 5%;
  font-size: 10px;
  width: 9.5rem;
  color: black;
}
