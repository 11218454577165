@checkbox-size: @input-height;

.app-checkbox {
    margin: @base-margin-v 0;
    white-space: nowrap;
    position: relative;
    cursor: pointer;

    &::before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        height: @checkbox-size;
    }

    .checker {
        position: absolute;
        top: 0;
        left: 0;
        border: @input-border;
        border-radius: 50%;
        width: @checkbox-size;
        height: @checkbox-size;
        background: @color-bg-primary;

        > i {
            animation-name: appear;
            animation-duration: 300ms;
            font-size: @input-height;
            line-height: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }

    label {
        display: inline-block;
        margin: 0 0 0 @checkbox-size + @base-margin-h;
        white-space: normal;
        vertical-align: middle;
    }

    &:focus {

        .checker {
            border-color: @color-outline;
        }
    }
}
