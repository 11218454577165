.a-dropdown {
  position: relative;
  margin: @base-margin-v 0;
  user-select: none;
  cursor: pointer;

  .a-dropdown-container {

    .form-control {
      height: 42px; // hack :-(
    }

    &:focus {
      input, select, textarea, .form-control {
        border-color: @color-outline;
      }
    }
  }

  .a-dropdown-value {
    cursor: pointer;
  }

  .a-dropdown-list {
    padding: 0px;
    display: none;
    position: absolute;
    transform: translateX(-50%);
    min-width: 100%;
    color: @color-secondary;
    background: @color-bg-secondary;
    margin-top: -@base-margin-v;
    margin-left: 50%;
    border: @base-border;
    border-top: none;
    text-align: left;
  }
}

.a-dropdown {

    .overlay {
        display: none;
        //background: transparent;
    }

    &.show {

        .overlay {
            display: block;
        }

        .a-dropdown-value {
            position: relative;
            z-index: 3; // OverOverlay layer to ensure that it's over Overlay Layer
        }

        .a-dropdown-list {
            animation-name: appear;
            animation-duration: 300ms;
            display: block;
            z-index: 3; // OverOverlay layer to ensure that it's over Overlay Layer
        }
    }
}
