@dot-size: 6px;

.dot(@color) {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -0.5*@dot-size;
    width: @dot-size;
    height: @dot-size;
    background-color: @color;
    margin: auto;
    border-radius: 0.5*@dot-size;
}

.calendar {
    text-transform: @text-transform;
    position: relative;
    color: @color-secondary;
    background: @color-bg-secondary;
    padding: @base-padding-v @base-padding-h;
    font-weight: 700;
    margin-bottom: @base-margin-v;
    z-index: 0; // Content Layer to ensure that calendar is under any other overlay

    .calendar-header  {
        position: relative;
        float: left;
        width: 100%;
        text-align: center;
    }

    .calendar-btn {
        position: absolute;
        top: 0;

        &.left {
            left: 0;
        }

        &.right {
            right: 0;
        }
    }

    &.full {

        .calendar-content {
            height: 400px;
            overflow: auto;

            .calendar-weekdays {
                display: none;
            }
        }
    }

    &::after {
        content: "";
        display: block;
        clear: both;
    }
}

.calendar-week {

    &::after {
        content: "";
        display: block;
        clear: both;
    }
}

.calendar-day {
  width: 14.28%;
  float: left;
  text-align: center;
  margin: 6px 0;
  position: relative;
  line-height: 1;
  cursor: pointer;

  .calendar-day-text {
    width: @input-height;
    margin: 1px auto;
    padding: @input-padding-v 0;
  }

  &:hover:not(.disabled) {
    .calendar-day-text {
      &::before {
        content: "";
        display: block;
        border: 1px solid @color-primary;
        box-sizing: border-box;
        border-radius: 50%;
        position: absolute;
        width: @input-height;
        height: 100%;
        top: 0;
        z-index: -1;
        background-color: @color-bg-primary;
      }
    }
  }

  &.selected {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: @color-primary;
      opacity: .4;
      z-index: -1;
    }
  }

  &.selected-first, &.selected-last {
    .calendar-day-text {
      color: @color-bg-primary;

      &::after {
        content: "";
        position: absolute;
        width: @input-height;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        border-radius: 50%;
        background-color: @color-primary;
        z-index: -1;
      }
    }
  }

  &.selected-first {
    &::before {
      left: 50%;
    }
  }

  &.selected-last {
    &::before {
      right: 50%;
    }
  }

  &.marked {
    .calendar-day-text {
      color: inherit;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: 1px solid @color-primary;
        border-radius: 0;
        background-color: @color-bg-primary;
        border-left: none;
        border-right: none;
        z-index: -1;
        width: auto;
      }
    }
  }

  &.marked-first, &.marked-last {
    .calendar-day-text {
      border-radius: 50%;
      background-color: FFF;
      color: inherit;

      &::before {
        content: "";
        display: block;
        height: 100%;
        border: 1px solid @color-primary;
        box-sizing: border-box;
        border-radius: 50%;
        position: absolute;
        width: @input-height;
        height: 100%;
        top: 0;
        z-index: -1;
        background-color: @color-bg-primary;
      }
    }
  }

  &.marked-first {
    .calendar-day-text {
      border-right: none;

      &::after {
        left: 50%;
      }
    }
  }

  &.marked-last {
    .calendar-day-text {
      border-left: none;

      &::after {
        right: 50%;
      }
    }
  }

  &.occupied {
    color: @color-danger;

    &::after {
      .dot(@color-danger);
    }
  }

  &.arrival {
    color: orange;

    &::after {
      .dot(orange);
    }
  }

  &.departure {
    color: Highlight;

    &::after {
      .dot(Highlight);
    }
  }

  &.disabled {
    color: @color-disabled;
    cursor: default;

    &::after {
      .dot(@color-disabled);
    }
  }

  &.hidden {
    .calendar-day-text {
      display: none;
    }
  }
}

.calendar-legend {
  font-size: 0.55rem;
  margin: 5px;

  .calendar-legend-item {
    display: inline-block;
    vertical-align: middle;
    margin: @base-margin-v @base-margin-h;
  }

  .legend-icon {
    display: inline-block;
    width: 1.5*@dot-size;
    height: 1.5*@dot-size;
    border-radius: 50%;
    vertical-align: top;
    margin-right: @base-margin-h;

    &.selected {
      background: @color-primary;
    }

    &.occupied {
      background: @color-danger;
    }

    &.arrival {
      background: orange;
    }

    &.departure {
      background: Highlight;
    }
  }
}
