@media only screen and (max-width: @grid-m - 1) {
    .hide-s {
        display:none !important
    }
}

@media only screen and (min-width: @grid-m) and (max-width: @grid-l - 1) {
    .hide-m {
        display:none !important
    }
}

@media only screen and (min-width: @grid-l) {
    .hide-l {
        display:none !important
    }
}

.m-0 {
    margin: 0 !important;
}

.my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.p-0 {
    padding: 0 !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.py-1 {
    padding-top: @grid-padding !important;
    padding-bottom: @grid-padding !important;
}

.left {
    float: left;
    max-width: 100%;
}

.right {
    float: right;
    max-width: 100%;
}

.v-middle {
    vertical-align: middle;
}

.no-border {
    border: none;
}

.clearfix {
    &::after {
        content: "";
        display: block;
        clear: both;
    }
}

.text-case-upper {
    text-transform: uppercase;
}

.text-case-normal {
    text-transform: none;
}

.text-underline {
    text-decoration: underline;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-primary, mark {
    background-color: transparent;
    color: @color-primary;
}

.text-success {
    color: @color-success;
}

.text-danger {
    color: @color-danger;
}

.text-warn {
    color: @color-warn;
}

.text-secondary {
    color: @color-secondary;
}

.text-nowrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.bg-secondary {
    background-color: @color-bg-secondary;
}

.with-shadow {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1);
}

.has-absolute-children {
    position: relative;

    .on-absolute-right {
        position: absolute;
        top: 0;
        right: 0;
    }

    .outside-absolute-right {
        position: absolute;
        left:96%;
        top:-3.7rem;
    }
}

.h-100 {
    height: 100% !important;
}

.mw-100 {
    max-width: 100% !important;
}

.d-flex {
    display: flex !important;
}

.d-inline {
  display: inline !important;

  &-flex {
    display: inline-flex !important;
  }

  &-block {
    display: inline-block !important;
  }
}

.flex-column {
    flex-direction: column !important;
}

.flex-grow-1 {
    flex-grow: 1 !important;
}

