/** FontAwesome **/
@import "../icons/font-awesome/less/solid.less";
@import "../icons/font-awesome/less/regular.less";
@import "../icons/font-awesome/less/fontawesome.less";

.cssicon-size-and-margin(@basesize) {
    @icon-margin: ((@base-line-height - @basesize)/2);
    width: @basesize;
    height: @basesize;
    margin-top: @icon-margin;
    margin-bottom: @icon-margin;
}

.fonticon-size-and-margin(@basesize) {
    @icon-margin: ((@base-line-height - @basesize)/2);
    font-size: @basesize;
    margin-top: @icon-margin;
    margin-bottom: @icon-margin;
}

.imgicon {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  .cssicon-size-and-margin(@regular-icon-size);

  &.icon-l {
    .cssicon-size-and-margin(@large-icon-size);
  }

  /* Flags are from https://www.flaticon.com/packs/countrys-flags */
  &.flag-gb {
    background-image: url(../icons/flags/united-kingdom.svg);
  }

  &.flag-dk {
    background-image: url(../icons/flags/denmark.svg);
  }

  &.flag-se {
    background-image: url(../icons/flags/sweden.svg);
  }

  &.flag-de {
    background-image: url(../icons/flags/germany.svg);
  }

  &.flag-no {
    background-image: url(../icons/flags/norway.svg);
  }

  &.flag-fo {
    background-image: url(../icons/flags/faroe-islands.svg);
  }

  &.flag-gl {
    background-image: url(../icons/flags/greenland.svg);
  }

  &.flag-is {
    background-image: url(../icons/flags/Icelandic.png);
  }
}

.fa, .fas {
    font-size: 1.33333333rem;
    line-height: 0.75rem;
    vertical-align: -0.0667rem;
    width: @regular-icon-size;
}
