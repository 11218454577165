.cal-frame{
    width:100%;
    height:100%;
}

.cal-col {
  height: 13.3333%;
  width: 100%;
  margin-top:2px;
}

.day li {
  list-style-type: none;
  display: inline-block;
  width: 12.5%;
  text-align: center;
  margin-bottom: 5px;
  font-size: 20px;
  color: var(--primary-application-color);
  border: solid;
  border-width: 0.5px;
  border-color: #EEEEEE;
  padding: 5%;
}

.day {
  padding: 10px;
  background: #eee;
  height: 13.2%;
  margin: 0;
}

.weekdays-guid {
  margin: 0;
  padding: 10px;
}

.weekdays-guid li {
  display: inline-block;
  width: 12.5%;
  color: var(--primary-application-color);
  text-align: center;
}

.dayAvailable {
  border: 2px solid #75bfa0 !important;
}
