.list-group {

    .list-group-item {
        padding: @base-padding-v @base-padding-h;
        border-top: @base-border;

        &.action {
            cursor: pointer;

            &.active, &:hover {
                color: @color-bg-primary;
                background: @color-primary;
            }
        }
    }
}
