@media only screen and (min-width: 1170px) {
  .dialog-calendar {
    width: 30vw;
    height: 50vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #EEEEEE;
    z-index:5;
  }

  .show-desktop {
    display: block;
  }

  .show-mobile {
    display: none;
  }
}


.timeOfDayPicker{
    height:40vh;
    background-color:#EEEEEE;
}

.picker-title {
  width: 100%;
  height: 8vh;
  background-color: var(--primary-application-color);
}

.picker-body{
    margin-top:15px;
    padding-left:10px;
}

.picker-body-content {
  height:32vh;
  overflow: auto;
  overflow-x: hidden;
}


.search-form-container {
  @media only screen and (min-width: 1170px) {
    margin-top: 30vh;
  }

  -webkit-box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.1);
  background: rgba(0,0,0,0.7);
  backdrop-filter: blur(10px);
  padding: 20px;
  width:100%;
  overflow:hidden;
}

.search-room-details {
  @media only screen and (min-width: 1170px) {
    margin-top: 10vh;
  }

  @media only screen and (max-width: 1170px) {
    margin-top: 2vh;
  }

  background: rgba(0,0,0,0.7);
  height: 72vh;
  padding-top: 40px;
  width:90%;

  hr {
    border-color: var(--primary-application-color);
    margin-top: 20px;
    border-width: 4px;
    width: 20%;
    margin-left: 40%;
  }

  h4 {
    color: white;
    font-size: 40px;
  }

  .header {
    width: 100%;
    height: 50px;
    text-align: center;
  }

  .details-panel {
    max-height: 58vh;
    padding: 10px;
  }

  .details {
    background-color: #EEEEEE;
    width: 100%;
    height: 200px;
    padding: 10px;
    cursor:pointer;
    overflow:hidden;

    hr {
      border-color: var(--primary-application-color);
      margin-top: 10px;
      border-width: 2px;
      width: 50%;
      margin-left: 11rem;
    }
  }
}

.search-box-design {
  width: 100%;
  height: 100px;
  color: white;
  cursor: pointer;
  margin-top: 10px;
  background-color: rgba(255,255,255,0.2);
  padding-left: 7%;
  padding-top: 2%;

  hr {
    border-width: 1px;
    border-color: white;
    width: 92%;
    margin-top: 10px;
  }
}
