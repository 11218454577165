.calendar-input {
  cursor: pointer;
  user-select: none;

  .calendar-input-calendar {
    position: absolute;

    @media only screen and (min-width: 768px) {
      width: 60vw;
    }

    width: 100%;
    max-width: @max-content-width;
    left: 0;
    right: 0;
    margin: auto;
    display: none;
    margin-top: -@base-margin-v;
  }

  .overlay {
    display: none;
  }

  &.show {

    .overlay {
      display: block
    }

    .calendar-input-item {
      position: relative;
      z-index: 3; // OverOverlay layer to ensure that it's over Overlay Layer
    }

    .calendar-input-calendar {
      animation-name: appear;
      animation-duration: 300ms;
      display: block;
      z-index: 3; // OverOverlay layer to ensure that it's over Overlay Layer
    }
  }
}
