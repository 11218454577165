details {
  width: 100%;
  min-height: 5px;
  padding: 22.5px 35px 22.5px 22.5px;
  margin: 0 auto;
  position: relative;
  font-size: 22px;
  border: 1px solid rgba(0,0,0,.5);
  box-sizing: border-box;
  transition: all 1s;
}

details + details {
  margin-top: 20px;
}

details[open] {
  min-height: 50px;
  background-color: #f6f7f8;
  box-shadow: 2px 2px 20px rgba(0,0,0,.2);
}

details p {
  color: var(--primary-application-color);
  font-size:15px;
}

.details-container
{
    margin-top:40px;
}

summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
}

summary:focus {
  outline: none;
}

summary::-webkit-details-marker {
  display: none
}

.control-icon {
  fill: rebeccapurple;
  transition: .3s ease;
  pointer-events: none;
}

.control-icon-close {
  display: none;
}

details[open] .control-icon-close {
  display: initial;
  transition: .3s ease;
}

details[open] .control-icon-expand {
  display: none;
}

.sendtocart {
  animation: xAxis 1s forwards cubic-bezier(1.000,0.440,0.840,0.165);
}

@keyframes xAxis {
  100% {
    position: fixed;
    top: 11.7rem;
    right: 0;
    z-index: 2;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}
