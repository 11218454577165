label {
    display: block;
    margin-bottom: @base-margin-v;
    text-transform: @text-transform;
}

input, select, textarea, .form-control {
    font-family: inherit;
    color: @color-secondary;
    background-color: @color-bg-secondary;
    border: @input-border;
    width: 100%;
    font-size: inherit;
    line-height: inherit;
    margin: @base-margin-v 0;
    padding: @input-padding-v @input-padding-h;
    box-shadow: none;
    border-radius: 3px;//@input-border-radius;

    &:focus {
        outline: none;
        border-color: @color-outline;
    }

    &.ng-touched {
        &.ng-invalid {
            color: @color-danger;
            border-color: @color-danger;

            &::placeholder {
                color: lighten(@color-danger, 10%);
            }
        }
    }

    &.disabled {
        opacity: .3;
    }
}

textarea {
    white-space: normal;
    overflow: auto;
    resize: none;
}

select {
    height: @base-line-height + 2*(@input-padding-v + @input-border-size);
}

.form-text {
    margin: @base-margin-v 0;
    line-height: @input-height;
}
