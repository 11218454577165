.messenger {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
    z-index: 11;

    .message {
        animation-name: appear;
        animation-duration: 300ms;
        z-index: 2;
        position: relative;
        width: 70%;
        left: 15%;
        margin: @base-padding-v 0;
    }
}
