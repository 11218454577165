nav {
  @media only screen and (min-width: 1295px) {
    @item-height: @base-line-height + 2*@base-padding-v;
    color: @color-bg-primary;
    background-color: @color-primary;
    width: 100%;

    .nav-wrapper {
      display: flex;
      align-items: center;
      min-height: @item-height;
      padding: @base-padding-v 0;
      height: 5vh;

      &::after {
        content: "";
        display: block;
        clear: both;
      }
    }

    .navbar {
      padding: 0 @base-padding-h;

      .nav-item {
        margin: 0 @base-margin-h;
        vertical-align: middle;
        min-width: 50px;
      }

      .nav-link {
        color: @color-bg-primary;
      }
    }

    .navbar-left {
      padding: 0 @base-padding-h;
      margin-left: 14%;

      .nav-item {
        margin: 0 @base-margin-h;
        vertical-align: middle;
        min-width: 50px;
      }

      .nav-link {
        color: @color-bg-primary;
      }
    }
  }

  @media only screen and (max-width: 1295px) {
    @item-height: @base-line-height + 2*@base-padding-v;
    color: @color-bg-primary;
    background-color: @color-primary;
    width: 100%;

    .nav-wrapper {
      align-items: center;
      min-height: @item-height;
      padding: @base-padding-v 0;
      height: 5vh;

      &::after {
        content: "";
        display: block;
        clear: both;
      }
    }

    .navbar {
      padding: 0 @base-padding-h;
      float: right;

      .nav-item {
        margin: 0 @base-margin-h;
        vertical-align: middle;
        min-width: 50px;
        line-height: 40px;
      }

      .nav-link {
        color: @color-bg-primary;
      }
    }

    .dropmenu {
      z-index: 10;
      position: absolute;
      background-color: var(--primary-application-color);
      height: 50vh;
      left: 0;
      right: 0;
      padding: 20px;
      margin-top: 5px;
    }
  }
}

.logo-nav {
  width: 18vh;
  height: 12vh;
  display: inline-block;
  margin-left: 40px;
  position: absolute;
  margin-top: 10px;
  padding: 5px;
  box-shadow: 0 0 5px 5px rgba(0,0,0,.1);
  display: flex;
  align-items: center;
  background-color: rgba(255,255,255,0.7);
  color: rgba(180, 180, 180, 1);
  border: 1px solid rgba(0,0,0,0.8);
}

@media only screen and (max-width: 1295px) {
  .removeLogo {
    display: none;
  }
}

.column {
  float: left;
  width: 33.33333333%;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}
