.btn(@color, @color-bg) {
  color: var(--color-dynamic);
  border-color: @color;
  background-color: @color;
  //&:hover,
  //&:focus,
  &:active,
  &.active {
    color: @color;
    background-color: @color-bg;
  }

  @media only screen and (min-width: @grid-l) {
    &:hover {
      color: @color;
      background-color: @color-bg;
    }
  }
}

.btn {
    display: inline-block;
    border: @button-border-size solid @color-secondary;
    border-radius: @button-border-radius;
    text-decoration: none;
    text-align: center;
    text-transform: @text-transform;
    cursor: pointer;
    padding: @button-padding-v @button-padding-h;
    font-family: inherit;
    font-weight: 600;
    font-size: inherit;
    line-height: inherit;
    font: inherit;
    margin: @base-margin-v 0;
    transition: all 300ms ease;
    color: var(--color-dynamic);
    border-color: @color-secondary;
    background-color: @color-secondary;
    appearance: none;
    writing-mode: inherit;

    &:hover {
        text-decoration: none;
    }
    &.disabled,
    &[disabled] {
        cursor: default;
    }

    &.disabled,
    &[disabled] {
        opacity: 0.5;
    }

    .btn(@color-secondary, @color-bg-secondary);

    &.btn-primary {
        .btn(@color-primary, @color-bg-primary);
    }

    &.btn-success {
        .btn(@color-success, @color-bg-success);
    }

    &.btn-danger {
        .btn(@color-danger, @color-bg-danger);
    }

    &.btn-warn {
        .btn(@color-warn, @color-bg-warn);
    }

    &.btn-mini {
        @padding-v: 0.15*@button-height;
        font-size: 0.85rem;
        padding: @padding-v 0.35*@button-height;
        margin: 0;
    }

    &.btn-round {
        @margin-v: @base-margin-v;
        height: @button-height;
        width: @button-height;
        font-size: 1rem;
        padding: 0;
        margin: @margin-v 0;

        .fa, .fas {
            font-size: 1.33rem;
            line-height: 1.33rem;
            vertical-align: middle;
        }
    }
}

.checkoutButton {
  background-color: transparent;
  border-width: 2px;
  border-color: var(--color-dynamic);
  text-align: center;
  padding: 5px;
  width: 100%;
  font-size:12px;
  height: 2rem;
  transition: all 0.5s;
  cursor: pointer;
  cursor: pointer;
}

.checkoutButton span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.checkoutButton span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.checkoutButton:hover span {
  padding-right: 15px;
}

.checkoutButton:hover span:after {
  opacity: 1;
  right: 0;
}
