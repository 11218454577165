.grid-loop(@prefix, @columns, @total: @columns) when (@columns > 0) {
    &.@{prefix}@{columns} {
        width: percentage((@columns / @total));
    }
    .grid-loop(@prefix, @columns - 1, @total);
}

.grid-offset-loop(@prefix, @columns, @total: @columns) when (@columns > 0) {
    &.offset-@{prefix}@{columns} {
        margin-left: percentage((@columns / @total));
    }
    .grid-offset-loop(@prefix, @columns - 1, @total);
}

* {
    box-sizing: border-box;
}

.container-fluid {
    padding-right: @grid-padding;
    padding-left: @grid-padding;
    margin-right: auto;
    margin-left: auto;
}

.container {
    max-width: @max-content-width;
    width: 100%;
    padding-right: @grid-padding;
    padding-left: @grid-padding;
    margin: 0 auto;

  @media only screen and (min-width: @grid-m) {
        width: 85%;
    }
    @media only screen and (min-width: @grid-l) {
        width: 70%;
    }
}

.row {
    margin-left: -@grid-padding;
    margin-right: -@grid-padding;

    &:after {
        content: "";
        display: block;
        clear: both;
    }
}

.col {
    float: left;
    padding: 0 @grid-padding;
    min-height: 1px;

    &.no-gutters {
        padding: 0;
    }

    .grid-loop(s, 12);
    .grid-offset-loop(s, 12);

    @media only screen and (min-width: @grid-m) {
        .grid-loop(m, 12);
        .grid-offset-loop(m, 12);
    }

    @media only screen and (min-width: @grid-l) {
        .grid-loop(l, 12);
        .grid-offset-loop(l, 12);
    }
}

.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}

.col-xs-15 {
  width: 20%;
  float: left;
}

@media (min-width: 768px) {
  .col-sm-15 {
    width: 20%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-15 {
    width: 20%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-15 {
    width: 20%;
    float: left;
  }
}
