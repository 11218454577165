.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: #f87e7d;
}

.seating-date-calendar {
  &__inbasket {
    &:before {
      content: "";
      background: #75bfa0;
      height: 7px;
      position: absolute;
      top: initial;
      bottom: 5px;
      left: 0;
      right: 0;
      width: 7px;
      border-radius: 50%;
      margin-left: auto;
      margin-right: auto;
      z-index: 2;
    }
  }

  &__information {
    padding: 10px;
    font-size: 10px;
    display: inline-flex;

    &-occupied {
      display: flex;
      align-items: center;

      &-dot {
        width: 10px;
        height: 10px;
        background: #f87e7d;
        border-radius: 5px;
        margin-right: 5px;
        display: inline-block;
      }
    }

    &-inbasket {
      display: flex;
      align-items: center;

      &-dot {
        width: 10px;
        height: 10px;
        background: #75bfa0;
        border-radius: 5px;
        margin-right: 5px;
        display: inline-block;
      }
    }
  }
}

.seating-standalone {
  &__calendar {
    background-color: white;
    margin: 0 20px;

    &-reset {
      display: flex;
      justify-content: center;
      padding: 10px;
      cursor: pointer;
    }
  }

  &__seatings {
    margin-bottom: 20px;
    margin-left: 10px;
  }
}

.seatings {
  &__header {
    font-size: 32px;
    margin-bottom: 10px;
    padding: 0 10px;
  }

  &__divider {
    height: 1px;
    background-color: var(--primary-application-color);
    margin-bottom: 20px;
  }

  &__content {}
}

// CARD
.seating {
  display: flex;
  background-color: white;
  margin-bottom: 20px;
  cursor: pointer;
  min-height: 200px;

  &:hover {
    transform: scale(1.02);
    transition: transform .4s ease-in-out;
  }

  &__image, &__no-image {
    width: 200px;
  }

  &__image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  &__no-image {
    background-color: grey;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #999999;

    .fa {
      font-size: 80px;
      width: auto;
      line-height: inherit;
      vertical-align: inherit;
    }
  }

  &__content {
    flex-basis: 100%;
    padding: 0 10px;
    position: relative;
    overflow: hidden;

    &-header {
      padding-top: 15px;
      padding-bottom: 15px;
      font-size: 22px;
      color: var(--primary-application-color);
    }

    &-text {
      overflow-wrap: break-word;

      &--overlay {
        position: absolute;
        right: 0;
        left: 0;
        bottom: 0;
        height: 50px;
        background-image: linear-gradient(180deg, transparent, #F5F5F5);
      }
    }
  }
}

// Standalone page
.seating-standalone-page {
  &__calendar {
    background-color: white;
    margin: 0 20px;
  }

  &__navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 0 20px;

    &-back {
      color: var(--primary-application-color);
      font-size: 20px;
      cursor: pointer;

      i {
        width: auto;
      }
    }
  }

  &__content {
    background-color: white;
    padding: 20px;

    &-header {
      color: var(--primary-application-color);
      font-size: 32px;
      margin-bottom: 10px;
    }

    &-image {
      margin-bottom: 20px;
      position: relative;
      min-height: 200px;
      display: flex;

      &-background {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        right: 0;
        opacity: 0.2;
        filter: blur(5px);
      }

      &-src {
        z-index: 1;
        max-height: 300px;
      }
    }

    &-divider {
      height: 1px;
      background-color: var(--primary-application-color);
      margin-bottom: 20px;
    }
  }

  &__sidebar {
    margin: 20px 20px 0 20px;
  }

  &__total {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    padding: 15px;
    background-color: white;
  }

  &__add-to-basket {
    padding: 10px;
    border: 2px solid var(--primary-application-color);
    cursor: pointer;

    &:hover {
      background-color: var(--primary-application-color);
      color: var(--color-dynamic);
    }
  }

  &__back-button {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-dynamic);
    gap: 10px;
    font-size: 20px;
    cursor: pointer;

    i {
      font-size: 40px;
      width: auto;
    }
  }

  &__rates {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
  }

  &__rate {
    &-description, &-time, &-amount {
      display: flex;
      align-items: center;

      &--heading {
        color: var(--primary-application-color);
        font-weight: 700;
      }

      &--bold {
        font-weight: 700;
      }
    }

    &-description{
      flex-basis: percentage((4/12));
    }

    &-time {
      flex-basis: percentage((3/12));
    }

    &-amount {
      flex-basis: percentage((5/12));
    }

    &-description {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      &-name {

      }

      &-date {
        padding-top: 2px;
        font-weight: 400;
        font-size: 14px;
      }
    }

    &-select {
      max-width: 120px;
      margin: 0;
      padding:0;

      &--small {
        max-width: 90px;
      }

      &-addon {
        border: 0.0625rem solid #d6d5d5;
        padding: 0.625rem 1.25rem;
      }

      &-remove {
        padding-left: 10px;
        cursor: pointer;
      }
    }
  }
  &-wrapper {
    margin:5px;
    padding:10px;
    border: 1px solid black;
  }
}

.body-seating {
  background-color: white;
  border: 2px solid grey;

  &-title {
    padding-bottom: 10px;
    border-bottom: 1px solid grey;
    font-weight: 700;
    text-align: center;
    margin-top: 10px;
  }
}

.seating-dialog-add-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-weight: 700;
  cursor: pointer;
  border-radius: 50%;
  border:0.125rem solid var(--primary-application-color);
  background-color:white;

  &-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-size {
    max-width: 200px;
  }

  &--disabled {
    pointer-events: none;
  }
}

// Modal
.seating-message {
  background-color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 25px;

  &__header {
    margin-bottom: 20px;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
  }
}

#seating-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.test:after{
  content: "test";
}