.overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.90);
  z-index: 2; // Overlay Layer to ensure that overlay is over UI Layer
  text-align: center;

  &.overlay-filled {
    background: #EEEEEE; /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom, #EEEEEE, var(--primary-application-color), var(--primary-application-color)); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #EEEEEE, var(--primary-application-color), var(--primary-application-color)); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }

  &.overlay-main {
    z-index: 3; // SuperOverlay Layer to ensure that this overlay is over any other component
  }
}

.calendar-loading-overlay {
  position: absolute;
  z-index: 20;
  background: rgba(0, 0, 0, 0);
  width: 100%;
  height: 100%;
  margin-left: -20px;
  margin-top: -20px;
  cursor: default;
}
