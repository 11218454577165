.section1 {
  height: 100%;
  color: var(--color-dynamic);

  @media only screen and (min-width: 768px) {
    margin-top: 12%;
  }
}

.section1 .section-center {
  left: 0;
  right: 0;
}

.booking-form {
  position: relative;
  max-height: 100%;
  width: 100%;
  margin: auto;
  -webkit-box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.1);
  background: rgba(255,255,255,0.92);
}

.booking-form .booking-bg {
  min-height: 480px;
  position: absolute;
  left: 25px;
  top: -25px;
  bottom: -25px;
  width: 390px;
  background-size: cover;
  background-position: center;
  padding: 25px;
  -webkit-box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 10px 5px rgba(0, 0, 0, 0.7);
  overflow: hidden;
}

.booking-form .booking-bg::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: var(--primary-application-color);
}

.booking-form > form {
  margin-left: 405px;
  padding: 30px;
}

.booking-form .form-header {
  margin-bottom: 30px;
  margin-top: 20px;
  position: relative;
}

.booking-form .form-header h2 {
  margin-top: 0;
  margin-bottom: 15px;
  font-weight: 900;
  color: #fff;
  font-size: 29px;
  text-transform: capitalize;
}

.booking-form .form-header p {
  font-size: 18px;
  margin-top:40px;
}

.booking-form .form-control:focus {
  background: #f8f8f8;
}

.booking-form select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.booking-form .form-label {
  text-transform: uppercase;
  line-height: 24px;
  height: 24px;
  font-size: 14px;
  font-weight: 400;
  margin-left: 20px;
}

.booking-form .form-btn {
  margin-top: 30px;
  display:flex;
  justify-content: center;
}

@media only screen and (max-width: 1670px) {
  .booking-form .booking-bg {
/*    position: relative;
    left: 0;
    right: 0;
    bottom: 0;
    top: -15px;
    width: 95%;
    margin: auto;*/
    display:none;
  }

  .booking-form > form {
    margin-left: 0px;
  }
}
