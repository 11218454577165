@media only screen and (min-width: 1170px) {
  .item {
    vertical-align: top;
    width: 40rem;
    margin-right: auto;
    margin-left: auto;
  }

  .days li {
    list-style-type: none;
    display: inline-block;
    width: 14.26%;
    text-align: center;
    margin-bottom: 5px;
    font-size: 20px;
    color: var(--primary-application-color);
    border: solid;
    border-width: 0.5px;
    border-color: #EEEEEE;
    padding: 5%;
  }

  .item-scale {
    display: inline-block;
    vertical-align: top;
    height: 100%;
    width: 40%;
    margin-top: 20px;
  }

  .activeDate {
    transition: all .7s ease-out;
  }

  .item1 {
    display: inline-block;
    vertical-align: top;
    height: 100%;
    width: 58%;
    margin-left: 2%;
  }
}

@media only screen and (max-width: 1170px) {
  .item {
    vertical-align: top;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }

  .days li {
    list-style-type: none;
    display: inline-block;
    width: 14.26%;
    text-align: center;
    margin-bottom: 5px;
    font-size: 11px;
    color: var(--primary-application-color);
    border: solid;
    border-width: 0.5px;
    border-color: #EEEEEE;
    padding: 6%;
  }
}

.activeDay {
  transform: scale(1.1);
  box-shadow: 0 0 6px 6px rgba(0,0,0,.2);
}

.hide {
  opacity:0;
}

.card-image-Table {
  height: 14rem;
}

.centered-image-Table {
  overflow: hidden;
  height: 100%;

  img {
    display: inline-block;
    max-width: none;
    height: 14rem;
    width: 100%;
    margin: 0;
    object-fit: cover;
  }
}

.counter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5%;
}

.month {
  padding: 30px 20px;
  width: 100%;
  background: var(--primary-application-color);
  text-align: center;
}

.month ul {
  margin: 0;
  padding: 0;
}

.month ul li {
  color: white;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 5px;
}

.month .prev {
  float: left;
  padding-top: 5px;
}

.month .next {
  float: right;
  padding-top: 5px;
}

.weekdays {
  margin: 0;
  padding: 10px;
}

.weekdays li {
  display: inline-block;
  width: 14.26%;
  color: var(--primary-application-color);
  text-align: center;
}

.days {
  padding: 10px;
  background: #eee;
  margin: 0;
}

@media screen and (max-width:720px) {
  .weekdays li, .days li {
    width: 13.1%;
  }
}

@media screen and (max-width: 420px) {
  .weekdays li, .days li {
    width: 13.5%;
  }

  .days li .activeli {
    padding: 2px;
  }
}

@media screen and (max-width: 290px) {
  .weekdays li, .days li {
    width: 12.2%;
  }
}
