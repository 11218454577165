.expandable-media-list {
  .expandable-media-description {
    background: @color-bg-secondary;
    overflow: hidden;
    margin: @base-margin-v 0;

    .centered-image {
      height: 10rem; // 120px;
    }

    @media only screen and (min-width: @grid-l) {
      &::before, &::after {
        content: "";
        display: block;
      }
    }
  }

  .card {
    margin: @base-margin-v 0;

    &.active {
      position: relative;
      outline: 3px solid @color-primary;
      outline-color: @color-primary;
      -webkit-transform: scale(1.05);
      -ms-transform: scale(1.05);
      transform: scale(1.05);
      box-shadow: 0 0 6px 6px rgba(0,0,0,.2);
    }
  }

  .list-group {
    .list-group-item {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
