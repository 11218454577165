.btn-outline(@color, @color-bg) {
  color: @color;
  border-color: @color;
  background-color: @color-bg;
  //&:hover,
  //&:focus,
  &:active,
  &.active {
    color: @color-bg;
    background-color: @color;
  }

  @media only screen and (min-width: @grid-l) {
    &:hover {
      color: var(--color-dynamic);
      background-color: @color;
    }
  }
}

.btn {
    &.btn-primary-outline {
        .btn-outline(@color-primary, @color-bg-primary);
    }
}
