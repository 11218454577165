.overel-container {
    position: relative;

    .overel {
        position: absolute;
        padding: 0 @base-padding-h;
        transform: translate(0, -50%);

        &.overel-center {
            transform: translate(50%, -50%);
            right: 50%;
        }

        &.overel-right {
            right: 0;
        }
    }
}
