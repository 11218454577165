/* You can add global styles to this file, and also import other style files */
@import "./styles/variables.less";
@import "./styles/animations.less";
@import "../src/app/pages/landing-page/landingpage.less";
@import "../src/app/pages/table-reservation/tablereservation.less";
@import "../src/app/pages/giftcard/giftcardStyling.less";
@import "../src/app/pages/search/SearchStyles.less";
@import "../src/app/pages/search/SearchStylesDesign2.less";
@import "../src/app/pages/conference/conference.less";
@import "../src/app/pages/search/calendar-guidance/calendar-guidance.component.less";
@import "../src/app/pages/seating-standalone/seating-standalone.less";

html {
    font-size: 14px;

    @media only screen and (min-width: @grid-m) {
        font-size: 15px;
    }

    @media only screen and (min-width: @grid-l) {
        font-size: 16px;
    }

    @media only screen and (min-width: @grid-xl) {
        font-size: 17px;
    }
}

:root {
  --color-dynamic: #FFFFFF;
  --primary-application-color: #2C324D;
  --primary-application-color-text-icon: #2C324D;
  --primary-application-font: serif;
}

body {
  font-size: 1rem;
  line-height: (@base-line-height*1rem);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.disableScroll{
      overflow:hidden;
  }

}

//Scrollbar design
::-webkit-scrollbar {
  width: 0.7em;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.9);
}

::-webkit-scrollbar-thumb {
  background-color: var(--primary-application-color);
  outline: 1px solid slategrey;
  border-radius: 10px;
}

//Scrollbar design end

:focus {
    outline: none;
}

.header(@font-size) {
    @line-height: (@base-line-height*@font-size);
    @margin-v: ((@header-height - @line-height)/2);

    font-size: @font-size;
    line-height: @line-height;
    margin: @margin-v 0;
}

h1, .h1 {
    .header(1.2rem);
    font-weight: 600;
}

h2, .h2 {
    .header(1rem);
    text-transform: @text-transform;
    color: @color-primary;
    font-weight: 800;
}

h3, .h3 {
    .header(1.1rem);
    font-weight: 600;
}

h4, .h4 {
    .header(1rem);
    font-weight: 500;
}

h5, .h5 {
    .header(0.9rem);
    font-weight: 500;
}

h6, .h6 {
    .header(0.8rem);
    font-weight: 500;
}

p {
    margin: ((@header-height - @base-line-height*1rem)/2) 0;
}

strong, b, .font-weight-bold {
    font-weight: 600;
    text-transform: @text-transform;
}

.page-content {
  animation-name: appear;
  animation-duration: 300ms;
  margin: 3rem 0;

  @media only screen and (min-width: @grid-m) {
    margin: 3rem 0;
  }

  @media only screen and (max-width: 768px) {
    padding-bottom: 10vh;
  }
}

span {
}

ul {
    padding-left: 0;
    list-style-type: none;

    li {
        list-style-type: none;
    }
}

dl {
    margin: 0;

    dt {
        margin-bottom: (0.5*@base-margin-v);
        text-transform: @text-transform;
    }

    dd {
        font-weight: 600;
        margin: 0 0 @base-margin-v;
    }
}

hr {
    height: 1px;
    border: none;
    border-top: 1px solid @base-border-color;
    margin: 0;

    &.double {
        height: 3px;
        border-bottom: 1px solid @base-border-color;
    }
}

img {
    display: block;
    border: none;
    max-width: 100%;
    margin: auto;
}

.brand-logo {
    display: inline-block;
    white-space: nowrap;
    display: none;
}

.section {
    margin-top: @base-margin-v;
    margin-bottom: @base-margin-v;

    .subsection {
        margin-top: @base-margin-v;
    }
}

.content-section {
    // margin-top: @base-margin-v;
    margin-left: @regular-icon-size;
}

.department-presentation {
  background-color: @base-text-bg-color;
  box-shadow: 0px -1px 4px 0px rgba(0,0,0,0.25);

  img {
    max-height: 10rem; // 200px;
    margin-bottom: (0.5*@input-height);
  }

  @media only screen and (min-width: @grid-m) {
    border-bottom: 2px solid @base-border-color;
  }
}

@import "./styles/common/common.less";
@import "./styles/common/ContentDialog.less";
@import "./styles/button.less";
@import "./styles/overel.less";
@import "./styles/icons.less";
@import "./styles/with-toggle.less";

@import "./app/shared/components/checkbox/checkbox.less";
@import "./app/modules/loader/loader.less";
@import "./app/shared/components/dropdown/dropdown.less";
@import "./app/shared/components/calendar/calendar.less";
@import "./app/shared/components/calendar-input/calendar-input.less";
@import "./app/modules/step-list/step-list.less";
@import "./app/shared/components/expandable-media-list/expandable-media-list.less";
@import "./app/modules/messenger/messenger.less";
@import "./app/shared/components/popup/popup.less";

@import "./styles/helpers.less";

.text-upper {
    text-transform: @text-transform;
}

.small {
    font-size: 0.8em;
}

.a-dropdown {
    .a-dropdown-list {
        .a-dropdown-item {
            &.active {
                display: none;
            }
        }
    }
}

.card {

    @media only screen and (max-width: @grid-m - 1) {
        background-color: @base-text-bg-color;
    }

    .btn-close {
        position: absolute;
        right: 6px;
        top: 6px;
    }
}

@media only screen and (max-width: @grid-l - 1) {
    .card {
        &.active {
            .card-image {
                height: auto;

                img {
                    width: 100%;
                }
            }
        }
    }
}

nav {
    .navbar {
        a {
            color: @color-bg-primary;
            display: block;
        }
    }
}

nav.head-line {
  .nav-wrapper {
    display: block;

    @media only screen and (min-width: @grid-l) {
      .navbar {
        float: right;

        .col {
          width: auto;
        }
      }

      .navbar-left {
        float: left;

        .col {
          width: auto;
        }
      }
    }
  }
}

.calendar.full {
    .calendar-header  {
        text-align: right;
    }
}

.arrival-calendar {
    .calendar {
        @media only screen and (min-width: @grid-l) {
            width: 50%;
        }
    }
}

.calendar-page {
}

.room-page {
    .h4 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        p {
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0;
        }
    }
}

.add-on-page {
    .h4 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.customer-page {
}

.payment-page {
    .container {
        @media only screen and (min-width: @grid-m) {
            width: @text-content-width;
        }
    }
}

.reciept-page {
    .container {
        @media only screen and (min-width: @grid-m) {
            width: @text-content-width;
        }
    }
}

.payment-failed-page {
    .container {
        @media only screen and (min-width: @grid-m) {
            width: @text-content-width;
        }
    }
}

.app-btn-check {
    .btn {
        .app-add-text { display: inline; }
        .app-added-text, .app-remove-text { display: none; }

        &.active {
            .app-add-text { display: none; }
            .app-added-text { display: inline; }

            &:hover {
                background-color: @color-bg-danger;
                color: @color-danger;
                border-color: @color-danger;

                .app-added-text { display: none; }
                .app-remove-text { display: inline; }
            }
        }
    }
}
