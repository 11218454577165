.step-list {
    @item-size: @input-height;
    @item-border-size: @base-border-size;
    font-size: 1rem;
    white-space: nowrap;

    .step-list-item {
        display: inline-block;
        width: @item-size;
        height: @item-size;
        line-height: @item-size - 2 * @item-border-size;
        text-align: center;
        background: @color-bg-primary;
        border: @item-border-size solid @base-border-color;
        border-radius: @item-size / 2;
        margin: 0 @base-margin-h;

        .fa, .fas {
            vertical-align: middle;
        }

        &.active {
            border-color: @color-primary;
        }
    }
}

.step-list-container {
    padding: 0.5*@button-height 0;
    margin: 0.5*@button-height 0;
    height: 0.5*@button-height;
    overflow: hidden;
}
