.booking-form-design2 {
  position: relative;
  max-height: 100%;

  @media only screen and (max-width: 1670px) {
    width: 100%;
  }

  @media only screen and (min-width: 1671px) {
    width: 40%;
  }

  margin: auto;
  -webkit-box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, .92);
}

.booking-form-design2 > form {
  padding: 30px;
}

.booking-form-design2 .form-header {
  margin-bottom: 30px;
  margin-top: 20px;
  position: relative;
}

.booking-form-design2 .form-header h2 {
  margin-top: 0;
  margin-bottom: 15px;
  font-weight: 900;
  color: #fff;
  font-size: 29px;
  text-transform: capitalize;
}

.booking-form-design2 .form-header p {
  font-size: 18px;
  margin-top: 40px;
}

.booking-form-design2 .form-control:focus {
  background: #f8f8f8;
}

.booking-form-design2 select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.booking-form-design2 .form-label {
  text-transform: uppercase;
  line-height: 24px;
  height: 24px;
  font-size: 14px;
  font-weight: 400;
  margin-left: 20px;
}

.booking-form-design2 .form-btn {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 1670px) {
  .booking-form-design2 > form {
    margin-left: 0px;
  }
}
